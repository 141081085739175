import React, { useState, useRef, useEffect } from "react"
import { Stack, Typography } from "@mui/material"

export const TimerComp: React.FC = () => {
  const [date, setDate] = useState<string>(new Date().toLocaleDateString())
  const [time, setTime] = useState<string>(new Date().toLocaleTimeString())

  const timerRef = useRef(
    new Timer(
      (time) => setTime(time),
      (date) => setDate(date)
    )
  )

  useEffect(() => {
    timerRef.current.start()

    return () => timerRef.current.dispose()
  }, [])

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap="15px">
      <Typography variant="h5">{date}</Typography>

      <Typography variant="h5">{time}</Typography>
    </Stack>
  )
}

class Timer {
  private intervalId: NodeJS.Timer | null = null
  private date: string = new Date().toLocaleDateString()

  constructor(private timeSub: (time: string) => void, private dateSub: (date: string) => void) {}

  start(): void {
    if (this.intervalId) return

    this.intervalId = setInterval(() => {
      const date = new Date().toLocaleDateString()

      if (this.date !== date) {
        this.date = date
        this.dateSub(this.date)
      }

      this.timeSub(new Date().toLocaleTimeString())
    }, 1000)
  }

  dispose(): void {
    if (!this.intervalId) return

    clearInterval(this.intervalId)
    this.intervalId = null
  }
}
