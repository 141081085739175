import React, { useState } from "react"
import { IconButton, Menu, MenuItem, Typography } from "@mui/material"
import AppsIcon from "@mui/icons-material/Apps"

import { theme } from "@constants"
import { useLocalizationContext, useModeContext } from "@context"
import { EMode, ILocale } from "@types"

const getModes = (l: ILocale): { id: number; value: EMode; name: string }[] => {
  const { currentData, record } = l.header.modes

  return [
    {
      id: 0,
      value: EMode.STANDBY,
      name: currentData,
    },
    {
      id: 1,
      value: EMode.DEMO,
      name: record,
    },
  ]
}

export const WidgetsSelect: React.FC = () => {
  const { mode, setMode } = useModeContext()
  const { l } = useLocalizationContext()

  const modes = getModes(l)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = (value: EMode): void => {
    setMode(value)
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        id="widget-button"
        children={<AppsIcon htmlColor={theme.palette.primary.light} />}
        onClick={handleClick}
        aria-controls={open ? "widget-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      />

      <Menu
        id="widget-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(): void => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "widget-button",
        }}
      >
        {modes.map(({ id, value, name }) => (
          <MenuItem key={id} onClick={(): void => handleSelect(value)}>
            <Typography color={value === mode ? theme.palette.primary.light : undefined}>{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
