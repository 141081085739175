import React from "react"
import { Stack, Typography, styled } from "@mui/material"

export const MainIcon: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap="20px">
      <EQIcon />
      <SMainTitle children={title.toUpperCase()} />
    </Stack>
  )
}

const SMainTitle = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}))

const EQIcon = (): JSX.Element => (
  <svg width="61" height="36" viewBox="0 0 61 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.0795 36C33.8206 35.9996 33.5696 35.9135 33.3675 35.7559C33.1653 35.5984 33.024 35.3786 32.9668 35.1326L26.4725 6.96345L23.3214 26.1832C23.2877 26.4242 23.1733 26.6476 22.9958 26.8187C22.8184 26.9898 22.5879 27.099 22.3402 27.1294C22.0943 27.1582 21.8455 27.1084 21.6311 26.9875C21.4167 26.8667 21.2485 26.6812 21.1516 26.4591L17.5656 18.2587H1.98738C1.68556 18.2587 1.3961 18.1419 1.18268 17.934C0.969263 17.726 0.849365 17.444 0.849365 17.1499C0.849365 16.8558 0.969263 16.5738 1.18268 16.3658C1.3961 16.1579 1.68556 16.0411 1.98738 16.0411H18.3192C18.5426 16.0405 18.7612 16.1043 18.9475 16.2244C19.1337 16.3445 19.2794 16.5156 19.3662 16.7162L21.6979 22.0632L25.1625 0.931416C25.2037 0.676917 25.335 0.444482 25.5336 0.274374C25.7322 0.104267 25.9856 0.00720328 26.2499 0H26.2853C26.5362 0.00261576 26.7791 0.0862979 26.9759 0.237921C27.1727 0.389543 27.3123 0.600529 27.3728 0.837783L33.948 29.4209L38.1157 8.06735C38.1637 7.82381 38.2937 7.60286 38.4852 7.43949C38.6767 7.27613 38.9187 7.1797 39.1728 7.1655C39.4275 7.1505 39.6799 7.21971 39.8892 7.36192C40.0985 7.50414 40.2524 7.71102 40.326 7.94908L43.8665 19.3774L44.4228 16.8739C44.4837 16.6222 44.6326 16.399 44.8439 16.2425C45.0552 16.086 45.3159 16.0061 45.5811 16.0164H59.1513C59.4531 16.0164 59.7426 16.1332 59.956 16.3412C60.1694 16.5491 60.2893 16.8312 60.2893 17.1253C60.2893 17.4193 60.1694 17.7014 59.956 17.9093C59.7426 18.1173 59.4531 18.2341 59.1513 18.2341H46.5067L45.2119 24.0394C45.1583 24.2791 45.0248 24.4947 44.8321 24.6525C44.6394 24.8102 44.3984 24.9013 44.1469 24.9115C43.8954 24.9216 43.6476 24.8502 43.4422 24.7085C43.2367 24.5667 43.0854 24.3626 43.0117 24.1281L39.5117 12.9216L35.1821 35.0982C35.1339 35.346 35.0004 35.5705 34.8036 35.7344C34.6068 35.8984 34.3585 35.9921 34.0997 36H34.0795Z"
      fill="#8CC63F"
    />
  </svg>
)
