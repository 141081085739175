import React from "react"
import { MenuItem, Select, styled } from "@mui/material"

export const RegionSelect: React.FC<{ countries: { kg: string } }> = ({ countries }) => {
  const regionOptions = [{ id: 0, name: countries.kg }]

  return (
    <SRegionSelect value={regionOptions[0].id}>
      {regionOptions.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </SRegionSelect>
  )
}

const SRegionSelect = styled(Select)(({ theme }) => ({
  width: "400px",
  height: "32px",
  background: theme.palette.secondary.light,

  [theme.breakpoints.down("lg")]: {
    width: "220px",
  },
}))
