import React, { useCallback, useMemo } from "react"
import { MenuItem, Stack, Select, Typography, styled, SelectChangeEvent } from "@mui/material"

import { theme } from "@constants"
import { useLocalizationContext } from "@context"
import { ELang } from "@types"

const languageOptions = [
  { id: ELang.RU, tag: "Ru", name: "Русский" },
  { id: ELang.EN, tag: "En", name: "English" },
  { id: ELang.KG, tag: "Kg", name: "Кыргыз тили" },
]

export const LanguageSelect: React.FC = () => {
  const {
    l: { language },
    setLocale,
  } = useLocalizationContext()

  const languageMenuItems = useMemo(
    () =>
      languageOptions.map(({ id, tag, name }) => {
        const color = language === id ? theme.palette.primary.light : theme.palette.secondary.A400

        return (
          <MenuItem key={id} value={id}>
            <Stack direction="row" alignItems="center" gap="12px">
              <Typography color={color}>{tag}</Typography>
              <Typography>{name}</Typography>
            </Stack>
          </MenuItem>
        )
      }),
    [language]
  )

  const handleLanguageChange = useCallback((e: SelectChangeEvent<unknown>) => {
    setLocale(e.target.value as ELang)
  }, [])

  return (
    <SSelect
      value={language as ELang}
      onChange={handleLanguageChange}
      children={languageMenuItems}
      sx={{ width: "218px" }}
    />
  )
}

const SSelect = styled(Select)(({ theme }) => ({
  height: "32px",
  background: theme.palette.secondary.light,
}))
