import React from "react"
import { Stack, IconButton, styled } from "@mui/material"
import NotificationsIcon from "@mui/icons-material/Notifications"

import { theme } from "@constants"
import { useLocalizationContext } from "@context"
import { LanguageSelect, MainIcon, RegionSelect, TimerComp, WidgetsSelect } from "./fragments"

export const HeaderComp: React.FC = () => {
  const {
    l: {
      header: { title, countries },
    },
  } = useLocalizationContext()

  return (
    <SHeaderContainer>
      <MainIcon title={title} />

      <SMainWrapper>
        <RegionSelect countries={countries} />
        <LanguageSelect />
        <TimerComp />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <IconButton children={<NotificationsIcon htmlColor={theme.palette.primary.light} />} />

          <WidgetsSelect />
        </Stack>
      </SMainWrapper>
    </SHeaderContainer>
  )
}

const SHeaderContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  background: theme.palette.secondary.dark,
  width: "100%",
  height: "56px",
  padding: "0 50px",
}))

const SMainWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "40px",

  [theme.breakpoints.down("lg")]: {
    gap: "20px",
  },
}))
